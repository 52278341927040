import axios from 'axios';
import {
  FETCH_SESSION_STATUS_APPOINTMENT_REQUEST,
  FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS,
  FETCH_SESSION_STATUS_APPOINTMENT_FAILURE,
  CREATE_SESSION_STATUS_CANCELLATION_REQUEST,
  CREATE_SESSION_STATUS_CANCELLATION_SUCCESS,
  CREATE_SESSION_STATUS_CANCELLATION_FAILURE,
  UPDATE_SESSION_STATUS_COMPLETE_REQUEST,
  UPDATE_SESSION_STATUS_COMPLETE_SUCCESS,
  UPDATE_SESSION_STATUS_COMPLETE_FAILURE,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE,
  UPDATE_SESSION_STATUS_ADMIN_REQUEST,
  UPDATE_SESSION_STATUS_ADMIN_SUCCESS,
  UPDATE_SESSION_STATUS_ADMIN_FAILURE,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE,
  UNAUTH_USER,
} from '../types';

import { headers } from '../defaults';
import { API_URL } from '../../environment';

export function fetchAppointment(id, callback = () => {}) {
  return dispatch => {
    dispatch({ type: FETCH_SESSION_STATUS_APPOINTMENT_REQUEST });
    return axios
      .get(`${API_URL}/practice/appointments/${id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: FETCH_SESSION_STATUS_APPOINTMENT_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function cancelAppointment(
  appointmentId,
  userId,
  reason,
  callback = () => {},
) {
  return dispatch => {
    dispatch({ type: CREATE_SESSION_STATUS_CANCELLATION_REQUEST });

    const values = {
      cancellation: {
        user_id: userId,
        reason,
      },
    };

    axios
      .post(
        `${API_URL}/practice/appointments/${appointmentId}/cancellations`,
        values,
        headers(),
      )
      .then(response => {
        dispatch({
          type: CREATE_SESSION_STATUS_CANCELLATION_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: CREATE_SESSION_STATUS_CANCELLATION_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function completeAppointment(
  appointmentId,
  duration = null,
  callback = () => {},
) {
  return dispatch => {
    dispatch({ type: UPDATE_SESSION_STATUS_COMPLETE_REQUEST });

    const values = {
      status: 'completed',
      duration,
    };

    axios
      .put(
        `${API_URL}/practice/appointments/${appointmentId}`,
        values,
        headers(),
      )
      .then(response => {
        dispatch({
          type: UPDATE_SESSION_STATUS_COMPLETE_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: UPDATE_SESSION_STATUS_COMPLETE_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function fetchAdminAppointment(id, callback = () => {}) {
  return dispatch => {
    dispatch({ type: FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST });
    return axios
      .get(`${API_URL}/admin/appointments/${id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function updateAdminAppointmentStatus(
  appointmentId,
  status,
  callback = () => {},
) {
  return dispatch => {
    dispatch({ type: UPDATE_SESSION_STATUS_ADMIN_REQUEST });

    const values = {
      status,
    };

    axios
      .put(
        `${API_URL}/admin/appointments/${appointmentId}/status`,
        values,
        headers(),
      )
      .then(response => {
        dispatch({
          type: UPDATE_SESSION_STATUS_ADMIN_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: UPDATE_SESSION_STATUS_ADMIN_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function cancelAdminAppointment(
  appointmentId,
  userId,
  reason,
  callback = () => {},
) {
  return dispatch => {
    dispatch({ type: CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST });

    const values = {
      cancellation: {
        user_id: userId,
        reason,
      },
    };

    axios
      .post(
        `${API_URL}/admin/appointments/${appointmentId}/cancellations`,
        values,
        headers(),
      )
      .then(response => {
        dispatch({
          type: CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS,
          payload: response.data,
        });
        callback(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        dispatch({
          type: CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}
